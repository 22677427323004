// node modules
import * as React from 'react'
import { PageProps, Link } from 'gatsby'
import styled from 'styled-components'

// layout
import Layout from 'components/layout/layout'
import Seo from 'components/layout/seo'

// shared
import Container from 'components/shared/container'
import Icon from 'components/shared/icon'

// assets
import arrowIcon from 'assets/icons/arrow-right-black.svg'

const Wrapper = styled.div`
  padding-top: 205px;
  padding-bottom: 100px;
`

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  gap: 10px;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.black};
`

const NotFoundPage: React.FC<PageProps> = () => (
  <Layout>
    <Seo title="404: Nie znaleziono" />
    <Wrapper>
      <Container>
        <h1>Nie znaleziono strony</h1>
        <StyledLink to="/">
          <span>Wróć do strony głównej</span>
          <Icon src={arrowIcon} />
        </StyledLink>
      </Container>
    </Wrapper>
  </Layout>
)

export default NotFoundPage
